<template>
  <div class="content-body">
    <article class="body-article">
      <section class="article-section">
        <div class="section-body">
          <ReportView ref="reportViewComponent" :isPopup="false" />
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import {
  commonCodesGetComName,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import ReportView from "@/components/common/report/ReportView";
import { mapGetters } from "vuex";

export default {
  name: "DailyStoreReportRoomFront",
  components: {
    ReportView,
  },
  data() {
    return {
      reportData: {
        closeDiv: null,
        salesNetTotal: null, //판매금액 과세
        salesVatTotal: null, //판매금액 부가세
        salesNotaxTotal: null, //판매금액 비과세
        salesAmtTotal: null, //판매금액 합계h
        payAllTotAmt: null,
        payTotCard: null,
        payTotCash: null,
        payTotCredit: null,
        payTotDeposit: null,
        payTotFrpy: null,
        payTotGift: null,
        payTotPreCard: null,
        creditAmtTot: null,
        creditCntTot: null,
        saleAmtTot: null,
        saleCntTot: null,
        selectSanctnLineList: [],
        storeDailyAmtList: [],
        storeDailyPaymentList: [],
        storeDailyTaxAmt: {
          vatAmt: 0,
          specialTax: 0,
          eduTax: 0,
          vilTax: 0,
          fund: 0,
          totTax: 0,
        },
        storeDailyFrontPaymentTotal: {
          creditReturnCard: 0,
          creditReturnCash: 0,
          creditReturnDeposit: 0,
          payTotAmt: 0,
          totSalesAmt: 0,
          totSalesUnitPrice: 0,
          bsnDate: null,
        },
        storeDailyCardReportList: [],
      },
    };
  },
  computed: {
    ...mapGetters(["username"]),
  },
  methods: {
    async setData(data, bsnDate) {
      this.reportData = data;
      if (!this.reportData.storeDailyFrontPaymentTotal) {
        this.reportData.storeDailyFrontPaymentTotal = {
          creditReturnCard: 0,
          creditReturnCash: 0,
          creditReturnDeposit: 0,
          payTotAmt: 0,
          totSalesAmt: 0,
          totSalesUnitPrice: 0,
        };
      }
      this.reportData.storeDailyFrontPaymentTotal.bsnDate = bsnDate;
      await this.postReportPart();
    },
    print() {
      this.$refs.reportViewComponent.printReport();
    },
    async postReportPart() {
      const copiedReportData = JSON.parse(JSON.stringify(this.reportData));
      const maxRow = Math.max(
          copiedReportData.storeDailyAmtList.length,
          copiedReportData.storeDailyPaymentList.length
      );
      const maxStoreDailyCardRow = 11;

      if (copiedReportData.storeDailyAmtList.length < maxRow) {
        for (
            let i = 0;
            i <= maxRow - copiedReportData.storeDailyAmtList.length;
            i++
        ) {
          copiedReportData.storeDailyAmtList.push({});
        }
      }
      if (copiedReportData.storeDailyPaymentList.length < maxRow) {
        for (
            let i = 0;
            i <= maxRow - copiedReportData.storeDailyPaymentList.length;
            i++
        ) {
          copiedReportData.storeDailyPaymentList.push({});
        }
      }
      if (
          copiedReportData.storeDailyCardReportList.length < maxStoreDailyCardRow
      ) {
        for (
            let i = copiedReportData.storeDailyCardReportList.length;
            i < maxStoreDailyCardRow;
            i++
        ) {
          copiedReportData.storeDailyCardReportList.push({});
        }
      } else if (
          copiedReportData.storeDailyCardReportList.length > maxStoreDailyCardRow
      ) {
        copiedReportData.storeDailyCardReportList = copiedReportData.storeDailyCardReportList.slice(
            0,
            maxStoreDailyCardRow
        );
      }

      copiedReportData.closeDiv = commonCodesGetComName(
          "CLOSE_DIV",
          copiedReportData.closeDiv ||
          commonCodeGetComCodeDefaultValue("CLOSE_DIV")
      );

      copiedReportData.storeDailyPaymentList.map((item) => {
        if (item.storeCode === "FRONT") {
          item.payFrpy = 0;
        }
      });

      const reportData = {
        reportJson: {
          jsonData: copiedReportData,
          username: this.username,
          uniqueCode: this.$options.name,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
  },
};
</script>
