<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  ref="searchBsnDatePicker"
                  format="YYYY-MM-DD"
                  type="lookup-condition"
                  v-model="dailyStoreReportSearchOptions.bsnDate"
                />
              </li>
              <li class="item text">
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      new Date(dailyStoreReportSearchOptions.bsnDate)
                    ),
                  }"
                  >{{
                    getDayOfWeekCaption(
                      new Date(dailyStoreReportSearchOptions.bsnDate)
                    )
                  }}</span
                >)(<span
                  :style="{
                    color: getWeekdayWeekendCaptionColor(
                      new Date(dailyStoreReportSearchOptions.bsnDate)
                    ),
                  }"
                  >{{
                    getWeekdayWeekendCaption(
                      new Date(dailyStoreReportSearchOptions.bsnDate)
                    )
                  }}</span
                >)
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="prev">
                    <erp-button
                        button-div="GET"
                        v-on:click.native="onBsnDatePreChange"
                    />
                  </li>
                  <li class="next">
                    <erp-button
                        button-div="GET"
                        v-on:click.native="onBsnDateNextChange"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">매장</div>
            <ul class="content">
              <li class="item date">
                <ejs-dropdownlist
                  ref="detailBsnDiv"
                  v-model="storeCode"
                  :dataSource="storeCodes"
                  :allowFiltering="false"
                  :fields="storeCodesOptions"
                  cssClass="lookup-condition-dropdown"
                ></ejs-dropdownlist>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="print">
            <erp-button
                button-div="PRINT"
                @click.native="print"
                :is-shortcut-button="true"
            >
              인쇄
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
    <daily-store-report-front ref="main" v-if="isFrontPage" />
    <daily-store-report-room-front ref="main" v-else-if="isRoomFrontPage" />
    <daily-store-report-other ref="main" v-else />
  </div>
</template>

<script>
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import InputDate from "@/components/common/datetime/InputDate";
import DailyStoreReportFront from "@/views/daily-close/DaliyStoreReportFront";
import DailyStoreReportOther from "@/views/daily-close/DailyStoreReportOther";
import DailyStoreReportRoomFront from "@/views/daily-close/DaliyStoreReportRoomFront.vue";
import {
  getDateOfNext,
  getDateOfPrevious,
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getFormattedDate,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";
import GolfERPService from "@/service/GolfERPService";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodesGetCommonCodeByIdxAttrb,
} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import moment from "moment";

Vue.use(AsyncComputed);

export default {
  name: "DailyStoreReport",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    InputDate,
    DailyStoreReportFront,
    DailyStoreReportOther,
    ErpButton,
    DailyStoreReportRoomFront,
  },
  async created() {
    this.posStoreCodes = await GolfERPService.fetchStores(true);
    this.dailyStoreReportSearchOptions.bsnDate = getFormattedDate(new Date());
  },
  async mounted() {
    await this.onViewButtonClicked();
  },
  computed: {
    isRoomFrontPage() {
      return this.storeCode === "CFRONT";
    },
    storeCodes() {
      return commonCodesGetCommonCode("STORE_CODE",true).filter((data) =>
        this.posStoreCodes.find((rData) => rData.code === data.comCode)
      );
    },
  },
  data() {
    return {
      posStoreCodes: [],
      storeCodesOptions: { text: "comName", value: "comCode" },
      storeCode: "FRONT",
      isFrontPage: true,
      dailyStoreReportSearchOptions: {
        bsnDate: getFormattedDate(new Date()),
      },
      isPrintAreaCreate: true,
    };
  },
  methods: {
    getDayOfWeekCaptionColor,
    getDayOfWeekCaption,
    getWeekdayWeekendCaptionColor,
    getWeekdayWeekendCaption,
    onBsnDatePreChange() {
      this.dailyStoreReportSearchOptions.bsnDate = getDateOfPrevious(
        this.dailyStoreReportSearchOptions.bsnDate
      );
    },
    onBsnDateNextChange() {
      this.dailyStoreReportSearchOptions.bsnDate = getDateOfNext(
        this.dailyStoreReportSearchOptions.bsnDate
      );
    },
    async onViewButtonClicked() {
      if (!this.dailyStoreReportSearchOptions.bsnDate) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["영업일자"])
        );
      }

      this.isFrontPage = this.storeCode === "FRONT";

      const { data } = await GolfErpAPI.getCloseDiv({
        bsnDate: this.dailyStoreReportSearchOptions.bsnDate,
        storeCode: this.storeCode,
      });

      if (data !== "Y") {
        const bsnDate = moment(
          this.dailyStoreReportSearchOptions.bsnDate
        ).format("YYYYMMDD");
        const requestList = [
          "SP_CLOSE_PART_DIV",
          "SP_CLOSE_SALES_STORE",
          "SP_CLOSE_SALES_CLASS",
          "SP_CLOSE_CARD",
        ];
        const inParams = [bsnDate];

        let request = [];
        requestList.map((item) => request.push({ name: item, inParams }));

        await GolfErpAPI.putStoredProcedureExecute({
          storedProcedureInfos: request,
        });
      }

      try {
        let res = await GolfErpAPI.fetchDailyStoreReport(
            this.dailyStoreReportSearchOptions.bsnDate,
            this.storeCode
        );
        res.storeDailyPaymentList =
          res.storeDailyPaymentList
            .filter(item => commonCodesGetCommonCodeByIdxAttrb("STORE_CODE", 6, "Y").map(comCode => comCode.comCode).includes(item.storeCode))
            .map((data)=> {
              return {
                ...data,
                reducePay : (data.payDeposit + data.payPrecard)
              };
            });
        res.storeDailyAmtList.splice(res.storeDailyAmtList.findIndex(item => item.storeCode === "GRILL"), 1, {
          storeCode: "FOOD",
          storeName: "식음료",
          totAmt: res.storeDailyAmtList.filter(item => ["GRILL", "TEE"].includes(item.storeCode)).map(item => item.totAmt).reduce((acc, cur) => acc + cur, 0),
          netAmt: res.storeDailyAmtList.filter(item => ["GRILL", "TEE"].includes(item.storeCode)).map(item => item.netAmt).reduce((acc, cur) => acc + cur, 0),
          vatAmt: res.storeDailyAmtList.filter(item => ["GRILL", "TEE"].includes(item.storeCode)).map(item => item.vatAmt).reduce((acc, cur) => acc + cur, 0),
          notaxAmt: res.storeDailyAmtList.filter(item => ["GRILL", "TEE"].includes(item.storeCode)).map(item => item.notaxAmt).reduce((acc, cur) => acc + cur, 0),
          feeVatAmt: res.storeDailyAmtList.filter(item => ["GRILL", "TEE"].includes(item.storeCode)).map(item => item.feeVatAmt).reduce((acc, cur) => acc + cur, 0),
        });
        //그릴사이에 다른 코드가 있을경우가 있으므로  식음료에 해당하는 TEE, COOKSTAFF 를 삭제한다.(위에서
        res.storeDailyAmtList.splice(res.storeDailyAmtList.findIndex(item => item.storeCode === "TEE"), 1);
        res.storeDailyAmtList.splice(res.storeDailyAmtList.findIndex(item => item.storeCode === "COOKSTAFF"), 1);

        const storeName = commonCodesGetComName("STORE_CODE", this.storeCode);
        res = { ...res, storeName };

        this.$refs.main.setData(
            res,
            this.dailyStoreReportSearchOptions.bsnDate
        );
      } catch (e) {
        this.$refs.main.clear();
        this.errorToast("정산정보가 없습니다");

        throw e;
      }
    },
    print() {
      this.$refs.main?.print();
    },
  },
};
</script>
